<template>
  <div v-click-outside="hideOptions" class="select-field__cont">
    <label :for="name">
      <div class="flex align-center space-between">
        <p class="label">
          {{ label }} <span v-if="isOptional">(Optional)</span>
        </p>
        <slot name="secondary" />
      </div>
      <div class="select-field">
        <div
          :class="['select-field__input', showDropdown ? 'focused' : '']"
          @click.stop="toggleDropdown"
        >
          <input
            :value="selected.name"
            :type="type"
            readonly
            :placeholder="placeholder"
          />
          <svg
            :class="['select-field__toggle', { rotate: showDropdown }]"
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
          >
            <path
              d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z"
            />
          </svg>
        </div>
        <div
          v-show="showDropdown"
          :class="['select-field__options', { 'no-label': !label }]"
        >
          <p
            v-for="option in options"
            :key="option.id"
            :value="option.id"
            @click.stop="selectOption(option)"
          >
            {{ option.name }}
          </p>
          <slot />
        </div>
      </div>
    </label>
    <div v-if="showErrorMessage" class="error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
// Options should contain name and id field {name, id}
export default {
  name: 'FormSelectField',
  props: {
    label: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    name: {
      type: String,
      default: '',
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    showErrorMessage: {
      type: Boolean,
      required: false,
      default: true
    },
    isOptional: {
      type: Boolean,
      required: false,
      default: false
    },
    selected: {
      type: Object,
      required: false,
      default() {
        return {
          id: ''
        }
      }
    },
    // Options should be an array of objects that contains a name and id key, the name is used for display while the id is a unique key for each member
    options: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      showDropdown: false,
      input: ''
    }
  },
  computed: {
    filteredOptions() {
      return this.input
        ? this.options.filter((item) =>
            item.name.startsWith(this.input.toLowerCase())
          )
        : this.options
    }
  },
  methods: {
    selectOption(option) {
      this.$emit('update', option, this.name)
      this.showDropdown = false
    },
    hideOptions() {
      this.showDropdown = false
    },
    async toggleDropdown() {
      if (!this.showDropdown) {
        await this.$store.commit('toggleDropdowns', true)
        await this.$store.commit('toggleDropdowns', false)
        this.showDropdown = true
      } else {
        this.showDropdown = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@include select-field;
.select-field__input input {
  cursor: pointer;
}
</style>
