<template>
  <button
    :class="[type, { 'has-icon': hasIcon }]"
    :disabled="isDisabled || isLoading"
    @click.prevent.stop="$emit('click')"
    @mouseover="displayedText = hoverText || text"
    @mouseleave="displayedText = text"
  >
    <template v-if="!isLoading">
      <slot />
      {{ displayedText }}
      <slot name="secondary" />
    </template>
    <template v-else>
      <div :class="[type, 'lds-ellipsis']">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </template>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    text: {
      type: String,
      default: '',
      required: true
    },
    type: {
      type: String,
      default: 'primary',
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false
    },
    hasIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    hoverText: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      displayedText: ''
    }
  },
  mounted() {
    this.displayedText = this.text
  }
}
</script>

<style lang="scss" scoped>
// Default button styles
button {
  font-size: 15px;
  border-radius: 18px;
  padding: var(--spacing-xs) var(--spacing-md);
  outline: 0px;
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  border: 0px;
  transition: all 0.3s var(--ease-out-quint);
  height: 36px;
  min-width: 120px;
  &:active {
    transform: translateY(2px);
  }
}

// Core button types - Primary | Secondary | Greys
button {
  &.primary {
    background: var(--blue-7);
    position: relative;
    color: var(--white);
    span {
      color: var(--white);
    }
    &:hover {
      background-position: initial;
    }
    &:disabled {
      opacity: 0.7;
    }
    .has-icon {
      span {
        margin-right: 4px;
      }
    }
  }
  &.secondary {
    color: var(--blue-6);
    background-color: var(--black-2);
    border: 1px solid transparent;
  }
  &.tertiary {
    color: var(--white);
    background-color: var(--black-2);
    border: 1px solid transparent;
  }

  &.grey-1 {
    color: var(--white);
    background: var(--grey-1);
    border: 1px solid transparent;
  }
  &.red {
    color: var(--red-1);
    background-color: var(--black-2);
    border: 1px solid transparent;
  }

  &.white {
    color: var(--grey-1);
    background-color: var(--white);
    border: 1px solid transparent;
  }
}

// Button types variations by Size
button {
  &.small {
    min-width: 100px;
    @include flex;
    height: 30px;
    font-size: 14px;
  }
  &.large {
    height: 48px;
    border-radius: 24px;
    font-weight: 600;
  }
  &.xl {
    width: 200px;
    height: 56px;
    border-radius: 28px;
    font-weight: 600;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 46px;
  height: 100%;
}
.lds-ellipsis div {
  position: absolute;
  top: calc(50% - 3px);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--white);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 0px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 0px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 20px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 40px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(20px, 0);
  }
}
</style>
