<template functional>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0"
      height="0"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath id="clipTabHeader">
          <path
            fill="#FFFFFF"
            stroke="#000000"
            stroke-width="1.5794"
            stroke-miterlimit="10"
            d="M32 0C23.1633 0 16 7.16345 16 16C16 24.8365 8.83667 32 0 32H16H134H150C141.163 32 134 24.8365 134 16C134 7.16345 126.837 0 118 0H32Z"
          />
        </clipPath>
        <svg
          id="picturetab"
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <g>
            <path
              d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M6.6,16.2l2-2.67 c0.2-0.27,0.6-0.27,0.8,0L11.25,16l2.6-3.47c0.2-0.27,0.6-0.27,0.8,0l2.75,3.67c0.25,0.33,0.01,0.8-0.4,0.8H7 C6.59,17,6.35,16.53,6.6,16.2z"
              fill="#f6f6f9"
            />
          </g>
        </svg>
        <svg
          id="videotab"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6C2.89543 6 2 6.89543 2 8V16C2 17.1046 2.89543 18 4 18H15C16.1046 18 17 17.1046 17 16V14.25L20.4 16.8C21.0592 17.2944 22 16.824 22 16V8C22 7.17595 21.0592 6.70557 20.4 7.2L17 9.75V8C17 6.89543 16.1046 6 15 6H4Z"
            fill="#f6f6f9"
          />
        </svg>
        <svg
          id="videotab"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6C2.89543 6 2 6.89543 2 8V16C2 17.1046 2.89543 18 4 18H15C16.1046 18 17 17.1046 17 16V14.25L20.4 16.8C21.0592 17.2944 22 16.824 22 16V8C22 7.17595 21.0592 6.70557 20.4 7.2L17 9.75V8C17 6.89543 16.1046 6 15 6H4Z"
            fill="#f6f6f9"
          />
        </svg>
        <svg
          id="writetab"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.63745 4.9057C2.63745 3.80113 3.53288 2.9057 4.63745 2.9057H20.6375C21.742 2.9057 22.6375 3.80113 22.6375 4.9057V20.9057C22.6375 22.0103 21.742 22.9057 20.6375 22.9057H4.63745C3.53288 22.9057 2.63745 22.0103 2.63745 20.9057V4.9057ZM5.63745 6.9057C5.63745 6.35342 6.08517 5.9057 6.63745 5.9057H18.6375C19.1897 5.9057 19.6375 6.35342 19.6375 6.9057C19.6375 7.45799 19.1897 7.9057 18.6375 7.9057H6.63745C6.08517 7.9057 5.63745 7.45799 5.63745 6.9057ZM6.63745 9.9057C6.08517 9.9057 5.63745 10.3534 5.63745 10.9057C5.63745 11.458 6.08517 11.9057 6.63745 11.9057H18.6375C19.1897 11.9057 19.6375 11.458 19.6375 10.9057C19.6375 10.3534 19.1897 9.9057 18.6375 9.9057H6.63745ZM5.63745 14.9057C5.63745 14.3534 6.08517 13.9057 6.63745 13.9057H18.6375C19.1897 13.9057 19.6375 14.3534 19.6375 14.9057C19.6375 15.458 19.1897 15.9057 18.6375 15.9057H6.63745C6.08517 15.9057 5.63745 15.458 5.63745 14.9057ZM6.63745 17.9057C6.08517 17.9057 5.63745 18.3534 5.63745 18.9057C5.63745 19.458 6.08517 19.9057 6.63745 19.9057H13.6375C14.1897 19.9057 14.6375 19.458 14.6375 18.9057C14.6375 18.3534 14.1897 17.9057 13.6375 17.9057H6.63745Z"
            fill="#f6f6f9"
          />
        </svg>
        <svg
          id="closeicon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path
            fill="#ffffff"
            d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
          />
        </svg>
        <svg
          id="logoColored"
          width="60"
          height="61"
          viewBox="0 0 60 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 29.5984C0 13.2888 13.2216 0.0671883 29.5312 0.0671883C45.8409 0.0671883 59.0625 13.2888 59.0625 29.5984V45.3016C59.0625 53.4564 52.4517 60.0672 44.2969 60.0672H29.5312C13.2216 60.0672 0 46.8456 0 30.5359V29.5984Z"
            fill="url(#bluegradient)"
          />
        </svg>
        <linearGradient
          id="paint0_linear"
          x1="0"
          y1="0.0671883"
          x2="67.6337"
          y2="12.1703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00CAFF" />
          <stop offset="1" stop-color="#4F81FF" />
        </linearGradient>
        <linearGradient
          id="bluegradient"
          x1="0"
          y1="0.0671883"
          x2="67.6337"
          y2="12.1703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00CAFF" />
          <stop offset="1" stop-color="#4F81FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="10"
          y1="30"
          x2="118.242"
          y2="62.7959"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00CAFF" />
          <stop offset="1" stop-color="#4F81FF" />
        </linearGradient>

        <linearGradient
          id="redgradient"
          x1="0"
          y1="0"
          x2="27.4556"
          y2="4.99119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F21905" />
          <stop offset="1" stop-color="#FF764B" />
        </linearGradient>
        <linearGradient
          id="greengradient"
          x1="24.7868"
          y1="11.3584"
          x2="-0.876093"
          y2="11.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0499f2" />
          <stop offset="1" stop-color="#42fcdb" />
        </linearGradient>
        <linearGradient
          id="purplegradient"
          x1="-9"
          y1="13"
          x2="82.7519"
          y2="-2.52411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7242FF" />
          <stop offset="1" stop-color="#D0C0FF" />
        </linearGradient>
        <svg
          id="twitter"
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="58"
            height="57.3483"
            rx="28.6742"
            fill="url(#paint0_linear_1964_422)"
          />
          <path
            d="M43.1197 20.9301C42.1808 21.3374 41.1879 21.6066 40.1719 21.7295C41.2438 21.0891 42.0471 20.0817 42.4327 18.8941C41.4254 19.4937 40.3228 19.9162 39.1727 20.1432C38.4039 19.3095 37.3802 18.7546 36.2621 18.5654C35.144 18.3762 33.9947 18.5635 32.9945 19.0979C31.9943 19.6323 31.1998 20.4836 30.7355 21.5182C30.2713 22.5528 30.1635 23.7122 30.4292 24.8147C28.3925 24.7116 26.4003 24.1813 24.5819 23.2582C22.7635 22.335 21.1597 21.0396 19.8746 19.4562C19.4239 20.2433 19.187 21.1347 19.1876 22.0417C19.186 22.8841 19.3928 23.7138 19.7894 24.4569C20.186 25.2 20.7602 25.8335 21.4609 26.3011C20.6465 26.2789 19.8495 26.0604 19.1377 25.664V25.7265C19.1438 26.9067 19.5574 28.0486 20.3084 28.959C21.0595 29.8694 22.102 30.4925 23.2596 30.7227C22.814 30.8583 22.3514 30.9298 21.8856 30.9351C21.5632 30.9313 21.2416 30.9021 20.9238 30.8476C21.2535 31.8629 21.8914 32.7501 22.7488 33.3859C23.6062 34.0217 24.6405 34.3744 25.7078 34.395C23.9056 35.813 21.6806 36.5869 19.3875 36.5933C18.97 36.5947 18.5528 36.5697 18.1384 36.5184C20.4797 38.0301 23.2081 38.8326 25.995 38.8292C27.9182 38.8491 29.8261 38.4857 31.6072 37.7601C33.3884 37.0344 35.0071 35.9612 36.3688 34.6029C37.7305 33.2447 38.8079 31.6288 39.5381 29.8495C40.2683 28.0703 40.6366 26.1633 40.6216 24.2401C40.6216 24.0278 40.6216 23.8029 40.6216 23.5781C41.6017 22.8472 42.4471 21.9511 43.1197 20.9301Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1964_422"
              x1="-16.5"
              y1="60"
              x2="65.5"
              y2="1.86962e-06"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7DD8FF" />
              <stop offset="1" stop-color="#158DE3" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          id="copy-link"
          width="200"
          height="197"
          viewBox="0 0 200 197"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="199.239"
            height="197"
            rx="98.5"
            fill="url(#bluegradient)"
          />
          <path
            d="M80.8572 92.286C80.8572 88.9897 82.1666 85.8285 84.4974 83.4977C86.8282 81.1669 89.9895 79.8574 93.2857 79.8574H130.571C133.868 79.8574 137.029 81.1669 139.36 83.4977C141.691 85.8285 143 88.9897 143 92.286V129.572C143 132.868 141.691 136.029 139.36 138.36C137.029 140.691 133.868 142 130.571 142H93.2857C89.9895 142 86.8282 140.691 84.4974 138.36C82.1666 136.029 80.8572 132.868 80.8572 129.572V92.286Z"
            fill="var(--white)"
          />
          <path
            d="M68.4286 55C65.1323 55 61.9711 56.3094 59.6402 58.6402C57.3094 60.9711 56 64.1323 56 67.4286V104.714C56 108.011 57.3094 111.172 59.6402 113.503C61.9711 115.833 65.1323 117.143 68.4286 117.143V67.4286H118.143C118.143 64.1323 116.833 60.9711 114.503 58.6402C112.172 56.3094 109.011 55 105.714 55H68.4286Z"
            fill="var(--white)"
          />
        </svg>
        <svg
          id="facebook"
          width="61"
          height="60"
          viewBox="0 0 61 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="60.6818"
            height="60"
            rx="30"
            fill="url(#paint0_linear_1964_415)"
          />
          <path
            d="M32.0019 44.7726V31.2954H36.5257L37.203 26.0432H32.0019V22.6898C32.0019 21.1691 32.4242 20.1328 34.605 20.1328L37.3863 20.1315V15.434C36.0401 15.2916 34.6872 15.2225 33.3335 15.2273C29.3235 15.2273 26.5782 17.6749 26.5782 22.1699V26.0433H22.0427L22.0427 31.2956H26.578L26.578 44.7727L32.0019 44.7726Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1964_415"
              x1="0"
              y1="30"
              x2="60.6818"
              y2="30"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4C74AF" />
              <stop offset="1" stop-color="#4B68FF" />
            </linearGradient>
          </defs>
        </svg>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SvgClipPaths'
}
</script>

<style lang="scss" scoped>
div {
  height: 0px;
}
</style>
