<template>
  <div>
    <div v-if="!hasContent && contentLoading" class="loaders">
      <div v-for="n in 3" :key="n">
        <svg
          width="556"
          height="193"
          viewBox="0 0 556 193"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="20.0315" cy="20.1886" r="20" class="blinkers__one" />
          <rect
            x="48.3911"
            y="10.1886"
            width="240.454"
            height="20"
            rx="4"
            class="blinkers__one"
          />
          <rect
            x="0.0315247"
            y="52.5781"
            width="555"
            height="140"
            rx="12"
            class="blinkers__two"
          />
          <path
            d="M1.37485 64.5781C1.37485 57.9507 6.74743 52.5781 13.3748 52.5781H161.375V192.578H13.3748C6.74743 192.578 1.37485 187.206 1.37485 180.578V64.5781Z"
            class="blinkers__one"
          />
          <rect
            x="182.006"
            y="73.0413"
            width="349.263"
            height="20"
            rx="4"
            class="blinkers__one"
          />
          <rect
            x="183.35"
            y="109.281"
            width="349.263"
            height="20"
            rx="4"
            class="blinkers__one"
          />
          <rect
            x="182.006"
            y="145.521"
            width="240.454"
            height="20"
            rx="4"
            class="blinkers__one"
          />
        </svg>
      </div>
    </div>
    <div v-else class="empty-grid">
      <img
        v-if="actionType === 'emptyPortfolio'"
        key="1"
        class="empty-portfolio"
        src="@/assets/illustrations/no-content.svg"
        alt
        srcset
      />
      <img
        v-else
        key="2"
        src="@/assets/illustrations/not-found.svg"
        alt
        srcset
      />
      <h4>{{ message.title }}</h4>
      <p>{{ message.subtitle }}</p>
      <base-button
        v-if="actionType && message.actionText"
        :text="message.actionText"
        @click="triggerAction(actionType)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyList',
  props: {
    actionType: {
      type: String,
      required: false,
      default: ''
    },
    hasContent: {
      type: Boolean,
      required: true,
      default: true
    },
    currentUser: {
      type: Object,
      required: false,
      default() {
        return {
          firstName: '',
          lastName: '',
          username: '',
          id: ''
        }
      }
    },
    contentLoading: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data() {
    return {
      message: {
        title: 'Nothing found',
        subtitle: 'We could not find any match for your search query'
      }
    }
  },
  computed: {
    isCurrentUser() {
      return this.userId === (this.currentUser && this.currentUser.id)
    }
  },
  watch: {
    currentUser() {
      this.getHeadings()
    },
    actionType: {
      immediate: true,
      handler() {
        this.getHeadings()
      }
    }
  },
  methods: {
    getHeadings() {
      if (this.actionType) {
        const obj = {
          search: {
            title: 'Dang! It is a bit empty in here',
            subtitle: 'We could not find any match for your search query.'
          }
        }
        this.message = obj[this.actionType]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-grid {
  height: 600px;
  @include flex($direction: column, $main: flex-start);
  width: 100%;
  padding: 10%;
  > * {
    max-width: 600px;
    text-align: center;
  }
  h4 {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 2rem;
    max-width: 360px;
    color: var(--grey-5);
  }
  img {
    width: 90%;
    height: auto;
  }
  .empty-portfolio {
    width: 50%;
  }
}
.loaders {
  width: 100%;
  div {
    padding: 1rem 1.5rem;
    width: 100%;
    animation: anim-opacity 0.5s linear infinite alternate;
    &:not(:last-child) {
      border-bottom: var(--border-1);
    }
  }
  svg {
    width: 100%;
    height: 100%;
  }
}
.blinkers {
  &__one {
    fill: var(--grey-1);
  }
  &__two {
    fill: var(--grey-2);
  }
}
#app.dark-theme {
  .blinkers {
    &__one {
      fill: var(--black-2);
    }
    &__two {
      fill: var(--grey-1);
    }
  }
}
@media (max-width: 600px) {
  .empty-grid {
    height: 400px;
  }
  #app.dark-theme {
    .blinkers {
      &__one {
        fill: var(--grey-1);
      }
      &__two {
        fill: var(--grey-2);
      }
    }
  }
}
</style>
