<template>
  <div class="toggle-input">
    <p>{{ label }}</p>
    <toggle-button :on="value" @toggle="$emit('toggle', name)" />
  </div>
</template>

<script>
import ToggleButton from './ToggleButton'
export default {
  name: 'ToggleInputField',
  components: {
    ToggleButton
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
</style>
