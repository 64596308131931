import uniqid from 'uniqid'
import SINGLE_IMAGE_UPLOAD from '@/graphql/common/SingleImageUpload.gql'
import MULTIPLE_IMAGE_UPLOAD from '@/graphql/common/MultipleImageUpload.gql'

export default {
  methods: {
    async getDataUrl({ event, returnArr = false }) {
      const pictureUrl = async (file) => ({
        file,
        id: await uniqid(),
        url: URL.createObjectURL(file)
      })
      if (event.target.files.length === 1 && !returnArr) {
        return await pictureUrl(event.target.files[0])
      } else {
        const pictures = []
        for (const file of event.target.files) {
          pictures.push(await pictureUrl(file))
        }
        return pictures
      }
    },

    async uploadToCloud({
      file,
      folder = 'temp',
      id = undefined,
      uploadProgress = {}
    }) {
      try {
        if (!Array.isArray(file)) {
          const data = await this.graphqlMutation({
            mutation: SINGLE_IMAGE_UPLOAD,
            variables: {
              file,
              id,
              folder
            },
            fetchOptions: uploadProgress
          })
          this.removeTypename(data.singleImageUpload)
          if (data && data.singleImageUpload) {
            return data.singleImageUpload
          }
        } else {
          const arr = []
          file.forEach((f) => {
            arr.push({ file: f.file, id: f.id })
          })
          const data = await this.graphqlMutation({
            mutation: MULTIPLE_IMAGE_UPLOAD,
            variables: {
              files: arr,
              folder
            },
            fetchOptions: uploadProgress
          })
          if (data && data.multipleImageUpload) {
            const multipleImageUpload = data.multipleImageUpload.map((item) => {
              this.removeTypename(item)
              return item
            })
            return multipleImageUpload
          }
        }
      } catch (error) {
        this.showNotifyBar({
          message: 'Unable to upload pictures, try again',
          type: 'error'
        })
      }
    }
  }
}
