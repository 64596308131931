<template>
  <div v-if="pageCount > 1" class="pagination">
    <div v-if="pageNumbers[0] > 1" @click.stop="goToPrevious">
      <span aria-label="Previous">&laquo;</span>
    </div>
    <div
      v-for="page in pageNumbers"
      :key="page"
      :class="{ 'page--current': current === page }"
      @click.stop="$emit('goToPage', page)"
    >
      <p>{{ page }}</p>
    </div>
    <div v-if="pageCount > pageNumbers[numPageList - 1]" @click.stop="goToNext">
      <span aria-label="Next">&raquo;</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    current: {
      /** Current Page */
      type: Number,
      default: 1,
      required: true
    },
    total: {
      /** Total count of all items in all pages */
      type: Number,
      default: 1,
      required: true
    },
    numPerPage: {
      /** Number of items you are displaying per */
      type: Number,
      default: 1,
      required: true
    },
    numPageList: {
      /** Number of clickable page list bullet to show at once */
      type: Number,
      default: 5,
      required: false
    }
  },
  data() {
    return {
      pageNumbers: []
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.numPerPage)
    }
  },
  created() {
    this.pageNumbers = []
    for (let i = 1; i <= this.numPageList; i++) {
      this.pageNumbers.push(i)
    }
  },
  methods: {
    goToPrevious() {
      if (!this.pageNumbers.includes(this.current - 1)) {
        this.pageNumbers = this.pageNumbers.map((i) => i - 1)
      }
      this.$emit('goToPage', this.current - 1)
    },
    goToNext() {
      if (!this.pageNumbers.includes(this.current + 1)) {
        this.pageNumbers = this.pageNumbers.map((i) => i + 1)
      }
      this.$emit('goToPage', this.current + 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  @include flex;
  width: 100%;
  margin: 4rem 0rem 0rem;

  > div {
    background: var(--grey-8);
    height: 24px;
    width: 24px;
    cursor: pointer;
    border-radius: 4px;
    border: var(--border-1);
    display: flex;
    justify-content: center;
    align-items: center;
    &.page--current {
      background: var(--grey-3);
      p {
        color: white;
      }
    }
    p {
      margin-bottom: 0rem;
      position: relative;
      top: 1px;
      font-size: 15px;
      font-weight: bold;
    }
  }
  > div:not(:last-child) {
    margin-right: 1rem;
  }
}
</style>
