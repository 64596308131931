import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing',
    redirect: { name: 'dashboard' }
  },
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "search" */ '../layouts/User.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
          import(/* webpackChunkName: "search" */ '../pages/Dashboard.vue')
      },
      {
        path: '/jobs',
        name: 'jobs',
        component: () =>
          import(/* webpackChunkName: "search" */ '../pages/Jobs.vue')
      },
      {
        path: '/talent-pool',
        name: 'talent-pool',
        component: () =>
          import(/* webpackChunkName: "search" */ '../pages/TalentPool.vue')
      },
      {
        path: '/messages',
        name: 'messages',
        component: () =>
          import(/* webpackChunkName: "search" */ '../pages/Messages.vue')
      },
      {
        path: '/settings',
        name: 'settings',
        component: () =>
          import(/* webpackChunkName: "search" */ '../pages/Settings.vue')
      },
      {
        path: 'applications/:applicationId',
        name: 'applications',
        component: () =>
          import(/* webpackChunkName: "search" */ '../pages/Application.vue')
      },
      {
        path: 'job/create',
        name: 'create-job',
        component: () =>
          import(/* webpackChunkName: "search" */ '../pages/CreateJob.vue')
      },
      {
        path: 'shortlists/:applicationId',
        name: 'shortlists',
        component: () =>
          import(/* webpackChunkName: "search" */ '../pages/Shortlists.vue')
      },
      {
        path: 'job/:jobId/edit',
        name: 'edit-job',
        component: () =>
          import(/* webpackChunkName: "search" */ '../pages/CreateJob.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
