<template>
  <div class="input-wrapper">
    <label :for="name">
      <div class="flex align-center space-between">
        <p class="label">
          {{ label }}
          <span v-if="isOptional">(Optional)</span>
        </p>
        <slot name="secondary" />
      </div>
      <div
        :class="[
          'input-inner-wrapper',
          hasSlot ? 'has-slot' : '',
          focused ? 'focused' : ''
        ]"
      >
        <input
          :type="type"
          :value="value"
          :autocomplete="name"
          spellcheck="false"
          :placeholder="placeholder"
          :[autofocus]="autofocus"
          :[readonly]="readonly"
          :[disabled]="disabled"
          @focus="focused = true"
          @blur="focused = false"
          @input="$emit('input', $event.target.value)"
        />
        <div v-if="hasSlot" key="has-slot">
          <slot />
        </div></div
    ></label>
    <div v-if="canHaveError" class="error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputField',
  props: {
    label: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    name: {
      type: String,
      default: '',
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    value: {
      type: [String, Number, Array],
      required: false,
      default: ''
    },

    maxlength: {
      type: String,
      required: false,
      default: ''
    },
    hasSlot: {
      type: Boolean,
      required: false,
      default: false
    },
    canHaveError: {
      type: Boolean,
      required: false,
      default: true
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    autofocus: {
      type: String,
      required: false,
      default: ''
    },
    readonly: {
      type: String,
      required: false,
      default: ''
    },
    isOptional: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      focused: false
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  @include input-wrapper;
}
.input-inner-wrapper {
  @include input-inner-wrapper;
}
.has-slot {
  padding-right: 2rem;
}
</style>
