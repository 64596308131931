import Vue from 'vue'
import VueApollo from 'vue-apollo'
import {
  createApolloClient,
  restartWebsockets
} from 'vue-cli-plugin-apollo/graphql-client'
import {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from 'apollo-cache-inmemory'
import * as Cookies from 'js-cookie'
import {
  TABULIO_TOKEN,
  TABULIO_USER,
  AUTHENTICATION_ERROR
} from './utils/constants'
import introspectionQueryResultData from './utils/apolloFragmentTypes'
import { customFetch } from './utils/custom-fetch'
import store from '@/store'
import router from '@/router'

// Install the vue plugin
Vue.use(VueApollo)

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const cache = new InMemoryCache({ fragmentMatcher })
// Name of the localStorage item

// Http endpoint
const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:3000/graphql'

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:3000/graphql',
  cache,
  // LocalStorage token
  tokenName: TABULIO_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  httpLinkOptions: {
    fetch: customFetch
  },
  // link: myLink
  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  getAuth: () => Cookies.get(TABULIO_TOKEN),
  defaultOptions: {
    fetchPolicy: 'no-cache'
  },
  apollo: {
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      }
    }
  }

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options
  })
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'no-cache'
      }
    },
    errorHandler(error) {
      if (error.message.includes(AUTHENTICATION_ERROR)) {
        store.commit('logoutUser')
        router.push('/').catch((e) => {})
        return null
      } else {
        // eslint-disable-next-line no-console
        console.log(
          '%cError',
          'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
          error.message
        )
      }
    }
  })

  return apolloProvider
}

// Manually call this when user log in
export async function onLogin(apolloClient, user) {
  if (typeof localStorage !== 'undefined' && user.token) {
    Cookies.set(TABULIO_USER, user.id, {
      expires: 30,
      domain: process.env.VUE_APP_COOKIE_DOMAIN
    })
    Cookies.set(TABULIO_TOKEN, user.token, {
      expires: 30,
      domain: process.env.VUE_APP_COOKIE_DOMAIN
    })
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  Cookies.remove(TABULIO_USER)
  Cookies.remove(TABULIO_TOKEN)
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.clearStore()
    // await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
