<template>
  <div class="overlay__cont overlay__cont--top">
    <transition name="fade-in" appear>
      <div
        v-if="showInnerModal"
        class="overlay__background"
        @click.self="$emit('hideInnerModal')"
      ></div>
    </transition>

    <slot />
  </div>
</template>
<script>
export default {
  name: 'OverlayWrapper',
  props: {
    showInnerModal: {
      type: Boolean,
      required: true,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 450px) {
  .overlay-wrapper {
    padding: 1rem;
  }
}
</style>
