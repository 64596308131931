<template>
  <div id="app" class="dark-theme">
    <div v-if="companyId">
      <router-view />
    </div>
    <svg-clip-paths />
    <notify-bar v-if="notifyBar.show === true && notifyBar.message" />
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import * as Cookies from 'js-cookie'
import SvgClipPaths from '@/components/common/SvgClipPaths'
import NotifyBar from '@/components/common/NotifyBar'
import GetUserCompany from '@/graphql/companies/GetUserCompany.gql'
import GET_USER_FROM_TOKEN from '@/graphql/users/GetUserFromToken.gql'
import logoutMixin from '@/components/bars/_logoutMixin'
import {
  TABULIO_TOKEN,
  TABULIO_USER,
  FACEBOOK_APP_ID,
  FACEBOOK_APP_VERSION
} from '@/utils/constants'

export default {
  components: {
    NotifyBar,
    SvgClipPaths
  },
  mixins: [logoutMixin],
  metaInfo: {
    title: 'Space | Hire top creatives'
  },
  computed: {
    notifyBar() {
      return this.$store.state.notifyBar
    }
  },
  async created() {
    await this.fetchUser()
  },
  mounted() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: FACEBOOK_APP_ID,
        autoLogAppEvents: false,
        xfbml: true,
        version: FACEBOOK_APP_VERSION
      })
    }
  },
  methods: {
    ...mapMutations(['loginUser', 'setCompany']),
    async fetchUser() {
      try {
        const user = Cookies.get(TABULIO_USER)
        const token = Cookies.get(TABULIO_TOKEN)
        if (!user) {
          this.goToMainSite()
        }
        const [userRes, companyRes] = await Promise.all([
          this.graphqlQuery({
            query: GET_USER_FROM_TOKEN,
            variables: {
              token
            }
          }),
          this.graphqlQuery({
            query: GetUserCompany,
            variables: {
              userId: user
            }
          })
        ])
        if (!userRes?.getUserFromToken) this.goToMainSite()
        this.loginUser({
          ...userRes.getUserFromToken,
          token
        })
        if (companyRes?.getUserCompany.length) {
          this.setCompany(companyRes.getUserCompany[0])
        } else {
          this.goToSpaceRegister()
        }
      } catch (error) {
        this.goToMainSite()
      }
    },
    goToMainSite() {
      location.href = `${process.env.VUE_APP_USER_FRONTEND}?tab=Hire`
    },
    goToSpaceRegister() {
      location.href = `${process.env.VUE_APP_USER_FRONTEND}/space/register`
    }
  }
}
</script>
<style lang="scss">
#app {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
}
</style>
