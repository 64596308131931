import Vue from 'vue'
import './mixins'
import './utils/registerGlobalComponents'
import './services'
import './plugins'
import ClickOutside from 'vue-click-outside'
import { sync } from 'vuex-router-sync'
import router from './router'
import store from './store'
import '@/assets/styles/app.scss'
import { createProvider } from './vue-apollo'
import App from './App.vue'

Vue.config.productionTip = false

Vue.directive('click-outside', ClickOutside)
const unsync = sync(store, router)

unsync()

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App)
}).$mount('#app-root')
