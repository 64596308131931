// These should not be changed

export const USER_NOT_FOUND = 'USER_NOT_FOUND'
export const VERIFICATION_CODE_INVALID = 'VERIFICATION_CODE_INVALID'
export const PASSWORD_INVALID = 'PASSWORD_INVALID'
export const VERIFICATION_CODE_EXPIRED = 'VERIFICATION_CODE_EXPIRED'
export const USER_ALREADY_EXIST = 'USER_ALREADY_EXIST'
export const USERNAME_AVAILABLE = 'USERNAME_AVAILABLE'
export const USERNAME_NOT_AVAILABLE = 'USERNAME_NOT_AVAILABLE'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const AUTH_COMPANY = 'auth-company'
export const AUTH_TOKEN = 'apollo-token'
export const INVALID_DOMAIN_EMAIL = 'INVALID_DOMAIN_EMAIL'

// Differentiate between the auth stoken stored in cache for development, staging, and production
const envUser =
  process.env.VUE_APP_NODE_ENV !== 'production'
    ? `_${process.env.VUE_APP_NODE_ENV}`
    : ''

export const TABULIO_TOKEN = `TABULIO_TOKEN${envUser}`

export const TABULIO_USER = `TABULIO_USER${envUser}`

export const FACEBOOK_APP_ID = '2430391850386446'
export const FACEBOOK_APP_VERSION = 'v13.0'
