<template>
  <div class="input-wrapper">
    <label :for="name">
      <div class="flex align-center space-between">
        <p class="label">
          {{ label }}
          <span v-if="maxlength"> (max: {{ maxlength }} char)</span>
        </p>
        <p v-if="maxlength" class="ml-auto light-grey">
          {{ Number(maxlength) - stringLength }}
        </p>
      </div>
      <div :class="['input-inner-wrapper', focused ? 'focused' : '']">
        <textarea
          :id="name"
          :maxlength="maxlength"
          :value="value"
          :placeholder="placeholder"
          @focus="focused = true"
          @blur="focused = false"
          @keydown="disableEnter"
          @input="textInput"
        />
      </div>
    </label>
    <div class="error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputField',
  props: {
    label: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    name: {
      type: String,
      default: '',
      required: false
    },

    value: {
      type: String,
      default: '',
      required: false
    },

    maxlength: {
      type: String,
      default: '',
      required: false
    },
    autocomplete: {
      type: String,
      default: '',
      required: false
    },
    isEnterDisabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      focused: false
    }
  },
  computed: {
    stringLength() {
      return this.value.length
    }
  },
  methods: {
    textInput(event) {
      this.$emit('input', event.target.value)
    },
    disableEnter(e) {
      const key = e.which || e.keyCode

      if (key === 13 && this.isEnterDisabled) {
        e.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  @include input-wrapper;
}
.input-inner-wrapper {
  width: 100%;
  border-radius: 10px;
  padding: 0.75rem;
  margin-top: 0.25rem;
  transition: var(--transition-1);
  &.focused {
    border-color: var(--blue-6);
  }
}

textarea {
  font-size: 1rem;
  width: 100%;
  height: 100px;
  outline: none;
  resize: none;
  border: 0px;
  background-color: transparent;
  @include hide-scroll;
  &:focus,
  &:active {
    outline: none;
  }
}
</style>
