<template>
  <div class="text-toggle in-text-toggle">
    <div
      :style="`left: ${getPosition.left}px;width: ${getPosition.width}px`"
    ></div>
    <button
      v-for="l in list"
      :key="l.id"
      :style="`width: ${l.width}px`"
      :class="['reset-btn', { selected: selected === l.id }]"
      @click.stop="$emit('toggleSelect', l.id)"
    >
      {{ l.text }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'TextToggle',
  props: {
    selected: {
      type: String,
      required: true,
      default: ''
    },
    list: {
      type: Array,
      required: true,
      default() {
        return []
      }
    }
  },
  computed: {
    getPosition() {
      return this.list.find((i) => i.id === this.selected)
    }
  }
}
</script>

<style lang="scss" scoped>
.text-toggle {
  background: var(--color-grey--1);
  border-radius: 0.5rem;
  position: relative;
  button {
    padding: 0.5rem 0.75rem;
    z-index: 1;
    position: relative;
    color: var(--color-grey);
    &.selected {
      color: var(--color-white);
    }
  }
  div {
    position: absolute;
    top: 0px;
    left: 0px;
    border: 2px solid var(--color-white);
    height: 100%;
    background: var(--color-grey--7);
    border-radius: inherit;
    transition: var(--transition--2);
  }
}
</style>
