import {
  format,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
  formatDistanceToNow,
  isToday,
  isThisYear
} from 'date-fns'
import { AUTHENTICATION_ERROR } from './_constants'

export default {
  computed: {
    userId() {
      return this.$store.state.user.id
    },
    companyId() {
      return this.$store.state.company.id
    },
    storeDropdown() {
      if (this.$store) {
        return this.$store.state.dropdowns
      } else {
        return null
      }
    },
    isMobile() {
      return innerWidth < 600
    }
  },
  watch: {
    storeDropdown(val) {
      if (!val) {
        this.showDropdown = false
      }
    }
  },
  methods: {
    viewPost(postId) {
      open(`${process.env.VUE_APP_USER_FRONTEND}/posts/${postId}`, '_blank')
    },
    viewUser(user) {
      open(`${process.env.VUE_APP_USER_FRONTEND}/user/${user}`, '_blank')
    },
    messageUser(user) {
      this.$router.push({ name: 'messages', params: { toUser: user } })
    },
    getImage(url, options = { size: 'small', name: '' }) {
      if (!url) {
        return this.getRandomImage(options.name)
      } else if (url.startsWith('https://cdn') && url.includes('tabulio')) {
        return url.replace('large.webp', `${options.size}.webp`)
      } else {
        return url
      }
    },
    pluralize(text, count) {
      return count > 1 ? `${text}s` : text
    },
    formatTextContent(text) {
      if (!text) return ''
      const replaceHttps = text.replace(
        /(https?:\/\/[^ ;|\\*'"!,()<>]+\/?)/g,
        (x) => {
          const t = x.replace('https://', '').replace('www.', '')
          return `<a href="${x}" class="u-hover-underline" style="color: inherit;" target="_blank" rel="noopener noreferrer">${t}</a>`
        }
      )
      const replaceUsernames = replaceHttps.replace(
        /(\B@\w+)/gi,
        (x) =>
          `<a href="/user/${x.slice(
            1
          )}" class="u-hover-underline" style="color: inherit;font-weight: bold;" target="_blank" rel="noopener">${x}</a>`
      )
      return replaceUsernames
    },
    async removeTypename(obj) {
      const normalize = (n) => {
        if (Array.isArray(n)) {
          n.forEach((o) => {
            if (typeof o === 'object' && o !== null) {
              remove(o)
            }
          })
        } else if (typeof n === 'object' && n !== null) {
          remove(n)
        }
      }
      const remove = (input) => {
        if (input.__typename) delete input.__typename

        Object.keys(input).forEach((i) => {
          normalize(input[i])
        })
      }

      await normalize(obj)
      return obj
    },
    formatTextLength(text, length) {
      if (text && text.length > length) {
        return text.split('').slice(0, length).join('').concat('...')
      } else {
        return text
      }
    },
    formatDate(val) {
      const date = new Date(val)
      const now = new Date()
      let newDate
      if (differenceInMinutes(now, date) < 1) {
        newDate = `${differenceInSeconds(now, date)}s`
      } else if (differenceInHours(now, date) < 1) {
        newDate = `${differenceInMinutes(now, date)}m`
      } else if (differenceInHours(now, date) < 24) {
        newDate = `${differenceInHours(now, date)}h`
      } else {
        const yr = differenceInYears(now, date) < 1 ? 'MMM do' : 'MMM do yyyy'

        newDate = format(date, yr)
      }
      return newDate
    },
    formatDateRelative(date) {
      return formatDistanceToNow(new Date(date))
    },
    thisDate() {
      return Date.now()
    },
    readableDate(val, options = {}) {
      const { noYear } = options
      if (!val) return
      const date = new Date(val)
      if (noYear) {
        return format(date, 'MMM do')
      }
      return format(date, 'MMM do, yyyy')
    },
    readableTime(val) {
      if (val) {
        const date = new Date(val)
        return format(date, 'h:mm a')
      }
    },
    readableDateTime(val) {
      const date = new Date(val)
      if (isToday(date)) {
        return format(date, 'h:mm aaa')
      } else if (isThisYear(date)) {
        return format(date, 'do MMM')
      } else {
        return format(date, 'dd/MM/yyyy')
      }
    },
    _copyLink(input) {
      const textarea = document.createElement('textarea')

      textarea.textContent = input

      textarea.style.height = '2em'

      textarea.style.width = '2em'

      textarea.style.position = 'fixed'

      textarea.style.zIndex = '-1'

      textarea.style.bottom = '0px'

      textarea.style.background = 'transparent'

      textarea.style.opacity = '0'

      textarea.style.right = '0px'

      document.body.appendChild(textarea)

      textarea.focus()

      textarea.select()

      textarea.setSelectionRange(0, 99999)
      try {
        document.execCommand('copy')
      } catch (error) {
        return
      } finally {
        document.body.removeChild(textarea)

        this.showDropdown = false
      }
    },
    async graphqlMutation({ mutation, variables, fetchOptions }) {
      try {
        const { data, error } = await this.$apollo.mutate({
          mutation,
          variables,
          context: {
            fetchOptions
          }
        })
        if (error) throw error
        return data
      } catch (error) {
        if (error.message.includes(AUTHENTICATION_ERROR)) {
          this.$store.commit('auth/showModal', 'emailSignin')
        } else {
          throw error
        }
      }
    },
    async graphqlQuery({ query, variables }) {
      try {
        const { data, error } = await this.$apollo.query({
          query,
          variables
        })
        if (error) throw error
        return data
      } catch (error) {
        if (error.message.includes(AUTHENTICATION_ERROR)) {
          this.$store.commit('auth/showModal', 'emailSignin')
        } else {
          throw error
        }
      }
    },

    _isFileSizeValid(file, maxSize) {
      // maxSize is in Kilobyte
      if (!file) throw new Error('file not provided')
      return file.size / 1024 < maxSize
    },

    showNotifyBar(payload) {
      payload.show = true
      this.$store.commit('handleNotifyBar', payload)
      setTimeout(() => {
        this.$store.commit('handleNotifyBar', { show: false })
      }, 4000)
    },
    showStaticNotifyBar(payload) {
      this.$store.commit('handleNotifyBar', payload)
    },
    removeUnderscore(val) {
      return val
        .split(/(?=[A-Z])/)
        .join(' ')
        .toLowerCase()
    },
    goBack() {
      this.$router.go(-1)
    },

    formatPrice(price) {
      if (!price) return '0'
      const num = price.toString().split('.')[0]
      const decimal = price.toString().split('.')[1]
      const arr = num.toString().split('')
      for (let i = arr.length - 1; i > 2; i -= 3) {
        const indexFromBack = arr.length - i + 2
        arr.splice(-indexFromBack, 0, ',')
      }
      if (decimal) {
        return arr.join('').concat('.', decimal)
      } else {
        return arr.join('')
      }
    }
  }
}
