import { onLogout } from '../../vue-apollo'

export default {
  mounted() {
    if (window.gapi) {
      window.gapi.load('auth2', async () => {
        await window.gapi.auth2.init({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID
        })
      })
    }
  },
  methods: {
    async logoutUser() {
      await onLogout(this.$apollo.provider.defaultClient)
      this.$store.commit('logoutUser')
      open(`${process.env.VUE_APP_USER_FRONTEND}?tab=Hire`)
    }
  }
}
