<template>
  <div :class="['dropdown-icon', type]" @click.stop="$emit('click')">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DropdownIcon',
  props: {
    type: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-icon {
  display: flex;
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
  transition: var(--transition-1);
  border-radius: 50%;
  @include button-click;
  &:hover {
    background-color: var(--black-2);
  }
  svg {
    fill: var(--grey-6);
  }
  &.big {
    height: 36px;
    background-color: var(--grey-2);
    border-radius: 18px;
    width: 36px;
    padding: 2px;
  }
}
@media (max-width: 600px) {
  .dropdown-icon {
    &:hover {
      background-color: unset;
    }
  }
}
</style>
