export default {
  data() {
    return {
      fillerImages: [
        'https://res.cloudinary.com/tabulio/image/upload/v1608629501/development/filler/filler-5_fcxh90.svg',
        'https://res.cloudinary.com/tabulio/image/upload/v1608629500/development/filler/filler-4_qmd2sm.svg',
        'https://res.cloudinary.com/tabulio/image/upload/v1608629500/development/filler/filler-3_hq21ir.svg',
        'https://res.cloudinary.com/tabulio/image/upload/v1608629500/development/filler/filler-6_ry2g35.svg',
        'https://res.cloudinary.com/tabulio/image/upload/v1608629500/development/filler/filler-2_mcq3ce.svg',
        'https://res.cloudinary.com/tabulio/image/upload/v1608629500/development/filler/filler-1_cz16ep.svg'
      ]
    }
  },
  methods: {
    getRandomImage(text) {
      let first, num
      if (text) {
        first = text.slice(0, 1).toLowerCase()
        const alphabet = 'abcdefghijklmnopqrstuvwxyz'
        num = alphabet.indexOf(first) % 5
      }
      const arr = [
        'https://res.cloudinary.com/tabulio/image/upload/v1608629501/development/filler/filler-5_fcxh90.svg',
        'https://res.cloudinary.com/tabulio/image/upload/v1608629500/development/filler/filler-4_qmd2sm.svg',
        'https://res.cloudinary.com/tabulio/image/upload/v1608629500/development/filler/filler-3_hq21ir.svg',
        'https://res.cloudinary.com/tabulio/image/upload/v1608629500/development/filler/filler-6_ry2g35.svg',
        'https://res.cloudinary.com/tabulio/image/upload/v1608629500/development/filler/filler-2_mcq3ce.svg',
        'https://res.cloudinary.com/tabulio/image/upload/v1608629500/development/filler/filler-1_cz16ep.svg'
      ]
      const index = num || 0
      return arr[index]
    }
  }
}
