<template>
  <div
    class="dropdown-card"
    :style="`maxHeight: calc(20px + ${lists.length * 45}px)`"
  >
    <div v-for="(list, index) in lists" :key="index">
      <router-link v-if="list.type === 'link'" key="link" :to="list.to">{{
        list.text
      }}</router-link>
      <button
        v-else
        key="button"
        class="reset-btn"
        @click.stop.prevent="$emit(list.action)"
      >
        {{ list.text }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DropdownCard',
  props: {
    lists: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-card {
  position: absolute;
  background-color: var(--black-2);
  border: var(--border-2);
  box-shadow: var(--box-shadow-3);
  border-radius: 8px;
  width: 180px;
  padding: 0.5rem;
  top: -2px;
  right: 0px;
  z-index: 10;
  animation: incr-height 0.3s var(--ease-out-quint) 1 forwards;
  overflow: hidden;
  .reset-btn {
    font-size: 15px;
  }
  button,
  a {
    padding: 0.75rem;
    width: 100%;
    text-align: left;
    border-radius: 8px;
    transition: var(--transition-1);
    &:hover {
      background: var(--grey-2);
    }
  }
}
@keyframes incr-height {
  from {
    max-height: 0px;
  }
}
@media (max-width: 600px) {
  .dropdown-card {
    right: 0px;
  }
}
</style>
