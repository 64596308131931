const stateDefault = {
  toUser: {
    id: '',
    image: {}
  },
  currentMessages: [],
  messengers: [],
  allMessageSummary: {
    noUnread: 0
  }
}

export default {
  namespaced: true,
  state() {
    return {
      ...stateDefault
    }
  },
  mutations: {
    setToUser(state, toUser) {
      state.toUser = toUser
    },
    setMessengers(state, { users, summary }) {
      state.messengers = users
      state.allMessageSummary = summary
    },
    resetStore(state) {
      state = { ...stateDefault }
    },
    setCurrentMessages(state, messages) {
      state.currentMessages = messages
    },
    handleCurrentMessenger(state, { show, id }) {
      if (show && id === state.toUser.id) {
        return
      }
      if (show) {
        state.toUser = {
          id,
          image: {}
        }
      } else {
        state.currentMessages = []
        state.toUser = {
          id: '',
          image: {}
        }
      }
      state.messengers = state.messengers.map((m) => {
        if (m.id === id) {
          m.messages = m.messages.map((i) => {
            i.isRead = true
            return i
          })
        }
        return m
      })
    },
    updateCurrentMessages(state, { payload, companyId }) {
      const targetUserId = state.toUser.id
      const { toUser, fromUser } = payload
      const toId = toUser.id
      const fromId = fromUser.id || fromUser
      if (targetUserId === fromId || targetUserId === toId) {
        state.currentMessages = state.currentMessages.concat(payload)
      }
      const arr = state.messengers.filter(
        (u) => u.id !== toId && u.id !== fromId
      )
      let current = state.messengers.find(
        (u) => u.id === toId || u.id === fromId
      )
      if (current) {
        current.messages = [payload]
      } else {
        const currentUser =
          companyId === payload.toUser.id ? payload.fromUser : payload.toUser

        current = {
          id: currentUser.id,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          img: {
            id: '',
            url: ''
          },
          messages: [payload]
        }
      }
      state.messengers = [].concat(current, arr)
    }
  },
  actions: {
    handleCurrentMessenger({ commit, rootState }, payload) {
      // if (!rootState.company.domainEmailVerified) {
      //   commit('auth/showModal', 'verificationRequired', { root: true })
      // } else {
      commit('handleCurrentMessenger', payload)
      // }
    }
  }
}
