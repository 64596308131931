a
<template>
  <div class="input-wrapper">
    <label :for="name">
      <div class="flex align-center space-between">
        <p class="label">
          {{ label }}
          <span v-if="maxlength"> (max: {{ maxlength }} char)</span>
        </p>
        <p v-if="maxlength" class="input__info">
          {{ Number(maxlength) - stringLength }}
        </p>
      </div>
      <div :class="['input-inner-wrapper', focused ? 'focused' : '']">
        <textarea
          v-if="!isContentEditable"
          :id="name"
          :maxlength="maxlength"
          :value="value"
          :placeholder="placeholder"
          @focus="focused = true"
          @blur="focused = false"
          @keydown="disableEnter"
          @input="textInput"
        />
        <div
          v-else
          :ref="name"
          :data-test-id="name"
          class="textarea"
          :placeholder="placeholder"
          :data-placeholder="placeholder"
          contenteditable
          @keypress.enter.prevent
          @input="textInput"
        ></div>
      </div>
    </label>
    <div class="error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextareaInputField',
  props: {
    label: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    name: {
      type: String,
      default: '',
      required: false
    },
    size: {
      type: String,
      default: '',
      required: false
    },

    value: {
      type: String,
      default: '',
      required: false
    },

    maxlength: {
      type: String,
      default: '',
      required: false
    },
    autocomplete: {
      type: String,
      default: '',
      required: false
    },
    isContentEditable: {
      type: Boolean,
      default: false,
      required: false
    },
    isEnterDisabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      focused: false
    }
  },
  computed: {
    stringLength() {
      return (this.value && this.value.length) || 0
    }
  },
  mounted() {
    if (this.isContentEditable) {
      // Listen to keypress and style at mentions
      // this.tagUsername(this.$refs[this.name])
      this.$refs[this.name].innerHTML = this.value
    }
  },
  methods: {
    textInput(event) {
      const text = this.isContentEditable
        ? this.$refs[this.name].textContent
        : event.target.value
      this.$emit('input', text)
    },
    disableEnter(e) {
      const key = e.which || e.keyCode

      if (key === 13 && this.isEnterDisabled) {
        e.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  @include input-wrapper;
}
.input-inner-wrapper {
  width: 100%;
  border-radius: 10px;
  padding: 0.75rem;
  border: var(--border-1);
  margin-top: 0.25rem;
  transition: var(--transition-1);
  background-color: var(--black-2);
  &.focused {
    border-color: var(--blue-7);
  }
}
textarea,
.textarea {
  @include hide-scroll;
  font-size: 16px;
  width: 100%;
  max-width: 100%;
  height: 140px;
  outline: none;
  resize: none;
  cursor: text;
  border: 0px;
  background-color: transparent;
  &:focus,
  &:active {
    outline: none;
  }
  &.small {
    height: 100px;
  }
}
.textarea {
  word-break: break-all;
  @include hide-scroll;
  overflow-y: auto;
  span {
    font-size: inherit;
  }
}
[contenteditable='true']:empty:not(:focus):before {
  content: attr(data-placeholder);
  color: var(--grey-5);
}
</style>
