const stateDefault = {
  modals: {
    domainVerification: {
      show: false
    },
    accountLocked: {
      show: false
    },
    domainConfirmation: {
      show: false
    },
    domainSuccessful: {
      show: false
    }
  },
  user: {
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    website: '',
    password: '',
    id: '',
    confirmPassword: '',
    verificationCode: ''
  },
  showCompanyModal: false,
  isSignup: false
}

export default {
  namespaced: true,
  state() {
    return {
      ...stateDefault
    }
  },
  mutations: {
    hideModals(state) {
      Object.keys(state.modals).forEach(
        (key) => (state.modals[key].show = false)
      )
    },
    handleCompanyModal(state, payload) {
      state.showCompanyModal = payload
    },
    resetStore(state) {
      state = { ...stateDefault }
    },
    showModal(state, payload) {
      Object.keys(state.modals).forEach(
        (key) => (state.modals[key].show = false)
      )
      state.modals[payload].show = true
    },
    handleIsSignup(state, payload) {
      state.isSignup = payload
    },
    editInput(state, payload) {
      state.user[payload.name] = payload.value
    },
    resetForm(state) {
      Object.keys(state.user).forEach((item) => (state.user[item] = ''))
    }
  }
}
