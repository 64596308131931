import Vue from 'vue'
import uploadLocalImage from './uploadLocalImage'
import globalHelper from './globals'
import filters from './filters'
import getRandomImage from './getRandomImage'

Vue.mixin(uploadLocalImage)

Vue.mixin(globalHelper)

Vue.mixin(filters)

Vue.mixin(getRandomImage)
