<template>
  <div class="page-is-loading">
    <div class="loading-wrapper">
      <loading-component />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IsLoading',

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.page-is-loading {
  z-index: 1000;
  height: calc(100vh - 56px);
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h5 {
    top: 50%;
    left: 50%;
    color: var(--white);
  }
}
.loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20vh;
}
</style>
