<template>
  <div class="toggle-wrapper" @click="$emit('toggle')">
    <div :class="['toggle', on ? 'on' : 'off']" />
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    on: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-wrapper {
  width: 40px;
  height: 12px;
  border-radius: 10px;
  background-color: #e4e4e4;
  position: relative;
  cursor: pointer;
}
.toggle {
  position: absolute;
  left: 0px;
  top: -4px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.on {
  transform: translateX(20px);
  background-color: var(--blue-6);
}
.off {
  background-color: #a5a5a5;
  transform: translateX(0px);
}
</style>
