import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

if (
  process.env.VUE_APP_NODE_ENV === 'staging' ||
  process.env.VUE_APP_NODE_ENV === 'production'
) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  })
}
