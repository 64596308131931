<template>
  <transition name="notify-bar-trans" appear>
    <div :class="[notifyClass, 'notify-bar adjust-popup']">
      <div>
        <svg
          v-if="notifyClass === 'success'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="2" y="2" width="20" height="20" rx="10" fill="white" />
          <path
            d="M9.60765 14.5878L7.28823 12.2684C7.02978 12.01 6.61891 12.01 6.36046 12.2684C6.10201 12.5269 6.10201 12.9377 6.36046 13.1962L9.13714 15.9729C9.39559 16.2313 9.81308 16.2313 10.0715 15.9729L17.096 8.95498C17.3545 8.69653 17.3545 8.28566 17.096 8.02721C16.8376 7.76876 16.4267 7.76876 16.1683 8.02721L9.60765 14.5878Z"
            fill="#27D48D"
          />
        </svg>
        <svg
          v-else-if="notifyClass === 'error'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.00137 20.0075H20.0614C21.6014 20.0075 22.5614 18.3375 21.7914 17.0075L14.2614 3.9975C13.4914 2.6675 11.5714 2.6675 10.8014 3.9975L3.27137 17.0075C2.50137 18.3375 3.46137 20.0075 5.00137 20.0075V20.0075ZM12.5314 13.0075C11.9814 13.0075 11.5314 12.5575 11.5314 12.0075V10.0075C11.5314 9.4575 11.9814 9.0075 12.5314 9.0075C13.0814 9.0075 13.5314 9.4575 13.5314 10.0075V12.0075C13.5314 12.5575 13.0814 13.0075 12.5314 13.0075ZM13.5314 17.0075H11.5314V15.0075H13.5314V17.0075Z"
            fill="white"
          />
        </svg>
        <svg
          v-else
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="2" y="2" width="20" height="20" rx="10" fill="white" />
          <path
            d="M11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12Z"
            fill="#515151"
          />
          <path d="M11 7H13V9H11V7Z" fill="#515151" />
        </svg>
        <p>{{ notifyBar.message }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'NotificationBar',
  computed: {
    notifyBar() {
      return this.$store.state.notifyBar
    },
    notifyClass() {
      return this.notifyBar.type === 'success'
        ? 'success'
        : this.notifyBar.type === 'error'
        ? 'error'
        : 'info'
    }
  },
  methods: {
    isOverlay() {
      const a = document.querySelector('.overlay__background')
      return !!a
    }
  }
}
</script>

<style lang="scss" scoped>
.notify-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  > div {
    @include flex;
    background-color: var(--opacity-black-80);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
  }
  svg {
    margin-right: 0.25rem;
  }
  p {
    font-size: 1rem;
    color: white;
    margin-bottom: 0rem;
  }
}
.notify-bar.error {
  > div {
    background-color: var(--red-1);
  }
}
.notify-bar.success {
  > div {
    background-color: var(--opacity-green-80);
  }
}
@media (max-width: 600px) {
  .notify-bar {
    bottom: unset;
    top: 6rem;
    left: 0rem;
    display: flex;
    justify-content: center;
    text-align: center;
    width: calc(100% - 4rem);
    margin: 0rem 2rem;
  }
}
</style>
