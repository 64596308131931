import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import uniqWith from 'lodash.uniqwith'
import isEqual from 'lodash.isequal'
import auth from './auth'
import message from './message'

Vue.use(Vuex)

export default new Store({
  modules: { auth, message },
  state: {
    user: {
      id: '',
      location: {
        state: '',
        country: ''
      },
      username: '',
      token: '',
      firstName: '',
      lastName: ''
    },
    company: {
      companyName: '',
      logo: {
        url: '',
        id: ''
      },
      location: {
        address: '',
        state: '',
        country: ''
      },

      website: '',
      numOfEmployees: '',
      description: '',
      user: {},
      deleted: false
    },
    sharePostModal: {
      show: false,
      post: {}
    },
    currentApplication: {
      show: false,
      details: {}
    },
    notifyBar: {
      show: false,
      type: 'info',
      message: ''
    },
    showDropdowns: false,
    allSkills: [],
    notifications: [],
    notificationCursor: {
      limit: 15,
      currentCount: 0,
      totalCount: 0,
      hasMore: false
    }
  },
  mutations: {
    handleNotifyBar(state, payload) {
      state.notifyBar = payload
    },
    toggleDropdowns(state, payload) {
      state.showDropdowns = payload
    },
    setUser(state, payload) {
      state.user = Object.assign(state.user, payload)
    },
    setCompany(state, payload) {
      state.company = payload
    },
    loginUser(state, payload) {
      state.user = payload
    },
    logoutUser(state) {
      state.user = {
        id: '',
        location: {
          state: '',
          country: ''
        },
        username: '',
        firstName: '',
        lastName: ''
      }
      state.company = {
        companyName: '',
        logo: {
          url: '',
          id: ''
        },
        location: {
          address: '',
          state: '',
          country: ''
        },

        website: '',
        numOfEmployees: '',
        description: '',
        user: {},
        deleted: false
      }
      state.token = ''
      state.notifications = []
    },
    setSkills(state, payload) {
      state.allSkills = payload
    },
    addNewNotification(store, payload) {
      const arr = [].concat(payload, store.notifications)
      store.notifications = uniqWith(arr, isEqual)
    },
    updateNotifications(store, payload) {
      const arr = [].concat(store.notifications, payload)
      store.notifications = uniqWith(arr, isEqual)
    },
    toggleSharePostModal(store, payload) {
      store.sharePostModal = payload
    },
    setNotificationCursor(store, payload) {
      store.notificationCursor = payload
    },
    markNotificationAsRead(store, id) {
      store.notifications = store.notifications.map((i) => {
        if (i.id === id) {
          i.isRead = true
        }
        return i
      })
    },
    markNotificationAsSeen(store) {
      store.notifications = store.notifications.map((i) => {
        i.isSeen = true
        return i
      })
    },
    onShowApplicationDetail(store, { show, details }) {
      if (show) {
        store.currentApplication = { show, details }
      } else {
        store.currentApplication = { show: false, details: {} }
      }
    }
  },
  actions: {
    loginUser({ commit }, payload) {
      commit('loginUser', payload)
    },
    logoutUser({ commit }) {
      commit('auth/resetStore')
      commit('message/resetStore')
      commit('logoutUser')
    }
  }
})
