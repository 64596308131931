<template>
  <button class="reset-btn" @click="$emit('click')">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42-.39-.39-1.02-.39-1.41 0l-6.59 6.59c-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'BackArrow'
}
</script>

<style lang="scss" scoped>
button {
  @include flex;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  background-color: var(--grey-2);
  transition: all 0.3s var(--ease-out-quint);
  margin-right: var(--spacing-sm);
  svg path {
    fill: var(--grey-6);
  }
}
</style>
