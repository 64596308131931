<template>
  <div :class="['poster-thumbnail', size, padding]">
    <div class="thumbnail-wrapper">
      <img
        key="has-image"
        :src="getImage(image.url, { size: 'small', name: username })"
        :alt="firstName"
        srcset=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PosterThumbnail',
  props: {
    image: {
      type: Object,
      default() {
        return {
          url: ''
        }
      }
    },
    firstName: {
      type: String,
      default: ''
    },
    username: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      required: false,
      default: 'small'
    },
    padding: {
      type: String,
      required: false,
      default: 'small'
    },
    emitAction: {
      type: String,
      required: false,
      default: ''
    },
    isAction: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.poster-thumbnail {
  padding: 0rem 0.5rem;
  position: relative;
  .thumbnail-wrapper {
    height: 40px;
    width: 40px;
  }
  img {
    width: inherit;
    height: inherit;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    background-color: var(--border-1);
  }
  &.big {
    .thumbnail-wrapper {
      height: 120px;
      width: 120px;
      margin-bottom: 1rem;
    }
  }
  &.medium {
    .thumbnail-wrapper {
      height: 60px;
      width: 60px;
    }
  }
  &.extra-small {
    .thumbnail-wrapper {
      height: 32px;
      width: 32px;
    }
  }
  &.pl-none {
    padding-left: 0px;
  }
}
.poster-thumbnail.nopadding {
  padding: 0px;
}
@media (max-width: 600px) {
  .poster-thumbnail.md-pl-none {
    padding-left: 0px;
  }
}
</style>
